ul {
  list-style: square;
}

a {
  text-decoration: none;
  color: black;
  font-size: 18px;
}

/* navbar */
.navbar {
  background-color: #fbcfe8;
  padding: 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  top: 0;
  position: relative;
  width: 100%;
  min-height: 70px;
}

/* logo  */
.logo {
  font-size: 30px;
  font-family: Arial, Helvetica, sans-serif;
}

/* ul  */
.navMenu {
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 60px;
}
.decor {
  color: #f472b6;
  font-family: "Alex Brush", Sans-serif;
  font-size: 46px;
  font-weight: 700;
  font-style: italic;
  line-height: 37px;
  letter-spacing: 1px;
  word-spacing: 0px;
  /* text-shadow: 16px -7px 3px rgba(251, 207, 232, 0.6); */
  margin-left: 45px;
  margin-top: 10px;
}
.aboutdecor {
  color: #f472b6;
  font-family: "Alex Brush", Sans-serif;
  font-size: 46px;

  font-style: italic;
  line-height: 37px;
  letter-spacing: 1px;
  word-spacing: 0px;
  margin-left: 45px;
  margin-top: 10px;
}
.paradecor {
  margin-left: 45px;
  text-align: left;
  color: #000000;
  font-family: "Lato", Sans-serif;
  font-size: 19px;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: 0.5px;
}
.decor2 {
  grid-row: span 3;
  /* Set background image */
  background-image: url("/public/Images/1.jpeg");
  /* Configure background size and positioning */
  background-size: cover; /* Cover the entire container */
  background-position: center; /* Center the background image */
  /* Additional background properties */
  background-repeat: no-repeat; /* Prevent image from repeating */
  /* Set height or width to contain the background image */
  height: 50%; /* Example height */
  width: 100%; /* Example width */
  /* Add other styles as needed */
}
.decor4 {
  grid-row: span 4;
  /* Set background image */
  background-image: url("/public/Images/Pic4.jpg");
  /* Configure background size and positioning */
  background-size: cover; /* Cover the entire container */
  background-position: center; /* Center the background image */
  /* Additional background properties */
  background-repeat: no-repeat; /* Prevent image from repeating */
  /* Set height or width to contain the background image */
  height: 80%; /* Example height */
  width: 100%; /* Example width */
  /* Add other styles as needed */
}
.decor5 {
  grid-row: span 4;
  /* Set background image */
  background-image: url("/public/Images/Pic3.jpg");
  /* Configure background size and positioning */
  background-size: cover; /* Cover the entire container */
  background-position: center; /* Center the background image */
  /* Additional background properties */
  background-repeat: no-repeat; /* Prevent image from repeating */
  /* Set height or width to contain the background image */
  height: 80%; /* Example height */
  width: 100%; /* Example width */
  /* Add other styles as needed */
}

.carouselimage {
  width: 100%; /* Ensure images take full width of their container */
  height: 700px; /* Set a fixed height for uniformity */
  object-fit: contain; /* Scale images uniformly while maintaining aspect ratio */
}
.decor3 {
  grid-row: span 2;
  /* Set background image */
  background-image: url("/public/Images/GaneshEntryway.jpg");
  /* Configure background size and positioning */
  background-size: cover; /* Cover the entire container */
  background-position: center; /* Center the background image */
  /* Additional background properties */
  background-repeat: no-repeat; /* Prevent image from repeating */
  /* Set height or width to contain the background image */
  height: 500px; /* Example height */
  width: 100%; /* Example width */
  /* Add other styles as needed */
}

.BookNowFont {
  color: #ffffff;
  font-family: "Lavishly Yours", Sans-serif;
  font-size: 55px;
}
.ContactFont1 {
  color: #f9a8d4;
  font-family: "Lavishly Yours", Sans-serif;
  font-size: 55px;
}
.GalleryFont {
  color: #f472b6;
  font-family: "Lavishly Yours", Sans-serif;
  font-size: 65px;
}

.ContactFont2 {
  font-family: "PT Serif", Sans-serif;
  font-size: 16px;
  font-weight: 400;
}
.ContactFont3 {
  color: #f9a8d4;
  font-size: 30px;
  font-weight: 600;
  line-height: 30px;
}
.ServiceFont {
  font-family: "PT Serif", Sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 30px;
}
.servicetext {
  color: #f472b6;
  font-family: "Alex Brush", Sans-serif;
  font-size: 56px;
  font-weight: 600;
}
.decor4mob {
  /* grid-row: span 3; */
  /* Set background image */
  background-image: url("/public/Images/Pic3.jpg");
  /* Configure background size and positioning */
  background-size: cover; /* Cover the entire container */
  background-position: center; /* Center the background image */
  /* Additional background properties */
  background-repeat: no-repeat; /* Prevent image from repeating */
  /* Set height or width to contain the background image */
  height: 400; /* Example height */
  width: 100%; /* Example width */
  /* Add other styles as needed */
}
.decor1mob {
  /* grid-row: span 3; */
  /* Set background image */
  background-image: url("/public/Images/Pic4.jpg");
  /* Configure background size and positioning */
  background-size: cover; /* Cover the entire container */
  background-position: center; /* Center the background image */
  /* Additional background properties */
  background-repeat: no-repeat; /* Prevent image from repeating */
  /* Set height or width to contain the background image */
  height: 400; /* Example height */
  width: 100%; /* Example width */
  /* Add other styles as needed */
}
.decor2mob {
  /* grid-row: span 3; */
  /* Set background image */
  background-image: url("/public/Images/1.jpeg");
  /* Configure background size and positioning */
  background-size: cover; /* Cover the entire container */
  background-position: center; /* Center the background image */
  /* Additional background properties */
  background-repeat: no-repeat; /* Prevent image from repeating */
  /* Set height or width to contain the background image */
  height: 400; /* Example height */
  width: 100%; /* Example width */
  /* Add other styles as needed */
}
.decor3mob {
  /* grid-row: span 2; */
  /* Set background image */
  background-image: url("/public/Images/GaneshEntryway.jpg");
  /* Configure background size and positioning */
  background-size: cover; /* Cover the entire container */
  background-position: center; /* Center the background image */
  /* Additional background properties */
  background-repeat: no-repeat; /* Prevent image from repeating */
  /* Set height or width to contain the background image */
  height: 600px; /* Example height */
  width: 100%; /* Example width */
  /* Add other styles as needed */
}
/* media queries */

@media screen and (max-width: 780px) {
  /* hamburger */
  .hamburger {
    padding-right: 35px;
    display: block;
    cursor: pointer;
    text-align: left;
    margin: 0 auto;
  }

  .bar {
    background-color: #120f0b;
    width: 20px;
    height: 3px;
    display: block;
    margin: 5px;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
  }

  /* adding active class to the bars  */
  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }
  .hamburger.active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }
  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }

  /* navMenu */

  .navMenu {
    z-index: 1000;
    position: absolute;
    flex-direction: column;
    gap: 0;
    top: 70px;
    left: -100%;
    text-align: start;
    width: 100%;
    transition: 0.7s ease-in-out;
    background-color: #ffffff;
    padding: 0px;
  }
  .centered-container {
    display: flex;
    /* justify-content: left; */
    /* align-items: left; */
    height: 100vh; /* Optional: Adjust height for vertical centering */
  }

  .navMenu.active {
    left: 0;
  }

  .navMenu li {
    margin: 16px 0;
  }
  .decor {
    color: #f472b6;
    font-family: "Alex Brush", Sans-serif;
    font-size: 30px;
    font-weight: 700;
    font-style: italic;
    line-height: 20px;
    letter-spacing: 0.5px;
    margin-right: 3px;
    word-spacing: 0px;
    /* text-shadow: 16px -7px 3px rgba(251, 207, 232, 1); */
    margin-left: 22px;
    margin-top: 10px;
  }

  .aboutdecor {
    color: #f472b6;
    font-family: "Alex Brush", Sans-serif;
    font-size: 30px;
    margin-right: 3px;

    font-style: italic;
    line-height: 20px;
    letter-spacing: 0.5px;
    word-spacing: 0px;

    margin-left: 22px;
    margin-top: 10px;
  }

  .paradecor {
    margin-left: 22px;
    margin-right: 22px;
    text-align: left;
    color: #000000;
    font-family: "Lato", Sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 33px;
    letter-spacing: 0.2px;
  }
  .BookNowFont {
    color: #ffffff;
    font-family: "Lavishly Yours", Sans-serif;
    font-size: 40px;
  }
  .servicetext {
    color: #f472b6;
    font-family: "Alex Brush", Sans-serif;
    font-size: 41px;
    font-weight: 600;
  }
  .GalleryFont {
    color: #f472b6;
    font-family: "Lavishly Yours", Sans-serif;
    font-size: 45px;
  }
  .ContactFont2 {
    font-family: "PT Serif", Sans-serif;
    font-size: 16px;
    font-weight: 400;
  }
  .ContactFont3 {
    color: #f9a8d4;
    font-size: 19px;
    font-weight: 600;
    line-height: 30px;
  }
}
